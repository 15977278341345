const urlId = new URLSearchParams(window.location.search);

export const environment = {
  production: false,
  baseApiUrl: "https://medaula-api-dev.sevn.dev/api",
  baseUrl: "https://medaula-api-dev.sevn.dev/api",
  baseUrlImage: "https://medaula-api-dev.sevn.dev",
  baseUrlDownload: "https://medaula-api-dev.sevn.dev/files/download",
  baseUrlUpload: "https://medaula-api-dev.sevn.dev/files/upload",
  medAulaId: urlId.get("source"),
};
