import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { TrialGuard } from './shared/guard/trial.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: './layout/layout.module#LayoutModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule',
  },
  {
    path: 'selecao-planos',
    loadChildren: './selecao-plano/selecao-plano.module#SelecaoPlanoModule',
  },
  {
    path: 'plataforma-trial',
    loadChildren: './layout-trial/layout-trial.module#LayoutTrialModule',
    canActivate: [TrialGuard],
  },
  {
    path: 'cadastro-simplificado',
    loadChildren: './cadastro-simplificado/cadastro-simplificado.module#CadastroSimplificadoModule',
  },
  {
    path: 'loja',
    loadChildren: './layout-loja/layout-loja.module#LayoutLojaModule',
  },
  {
    path: 'recuperacao-senha',
    loadChildren: './recuperacao-senha/recuperacao-senha.module#RecuperacaoSenhaModule',
  },
  {
    path: 'nova-senha',
    loadChildren: './nova-senha/nova-senha.module#NovaSenhaModule',
  },
  {
    path: 'videos',
    loadChildren: './video/video.module#VideoModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, TrialGuard],
})
export class AppRoutingModule {}
